import React, { lazy, Suspense } from 'react';
const Report = lazy(() => import('Pages/Report'));
const VotingScreen = lazy(() => import('Pages/VotingScreen'));
const NominationDetail = lazy(() => import('Pages/NominationDetail'));
const Election = lazy(() => import('Pages/Election'));
const Nomination = lazy(() => import('Pages/Nomination'));
const NominationList = lazy(() => import('Pages/NominationList'));
const ElectionConfiguration = lazy(() => import('Pages/ElectionConfiguration'));
const EmailComposer = lazy(() => import('Pages/EmailComposer'));
const NominationCategories = lazy(() => import('Pages/NominationCategories'));
const Auditors = lazy(() => import('Pages/Auditors'));
const Members = lazy(() => import('Pages/Members'));
const MemberAuditList = lazy(() => import('Pages/MemberAuditList'));
const MemberDetail = lazy(() => import('Pages/MemberDetail'));
const UploadMemberData = lazy(() => import('Pages/UploadMemberData'));
const ElectionResult = lazy(() => import('Pages/ElectionResult'));
//Static Pages
const ECMemberRole = lazy(() => import('Pages/ECMemberRole'));
const CategoryOfElectedSeats = lazy(() => import('Pages/CategoryOfElectedSeats'));
const EligibilityCriteria = lazy(() => import('Pages/EligibilityCriteria'));
const CandidateProfileEligibility = lazy(() => import('Pages/CandidateProfileEligibility'));
const VotingRules = lazy(() => import('Pages/VotingRules'));
const CodeOfConduct = lazy(() => import('Pages/CodeOfConduct'));
const ContactUs = lazy(() => import('Pages/ContactUs'));

const ProtectedRoutes = [
  {
    path: 'upload-member-data',
    element: (
      <Suspense>
        <UploadMemberData />
      </Suspense>
    ),
  },
  {
    path: 'auditors',
    element: (
      <Suspense>
        <Auditors />
      </Suspense>
    ),
  },
  {
    path: 'members',
    element: (
      <Suspense>
        <Members />
      </Suspense>
    ),
  },
  {
    path: 'member-audit-list',
    element: (
      <Suspense>
        <MemberAuditList />
      </Suspense>
    ),
  },
  {
    path: 'member-detail/:userId',
    element: (
      <Suspense>
        <MemberDetail />
      </Suspense>
    ),
  },
  {
    path: 'nomination-categories',
    element: (
      <Suspense>
        <NominationCategories />
      </Suspense>
    ),
  },
  {
    path: 'election',
    element: (
      <Suspense>
        <Election />
      </Suspense>
    ),
  },
  {
    path: 'nomination',
    element: (
      <Suspense>
        <Nomination />
      </Suspense>
    ),
  },
  {
    path: 'election-configuration',
    element: (
      <Suspense>
        <ElectionConfiguration />
      </Suspense>
    ),
  },
  {
    path: 'nomination-list',
    element: (
      <Suspense>
        <NominationList />
      </Suspense>
    ),
  },
  {
    path: 'nomination/:id',
    element: (
      <Suspense>
        <NominationDetail />
      </Suspense>
    ),
  },
  {
    path: 'email-composer',
    element: (
      <Suspense>
        <EmailComposer />
      </Suspense>
    ),
  },
  {
    path: 'voting',
    element: (
      <Suspense>
        <VotingScreen />
      </Suspense>
    ),
  },
  {
    path: 'election-result',
    element: (
      <Suspense>
        <ElectionResult />
      </Suspense>
    ),
  },
  {
    path: 'reports',
    element: (
      <Suspense>
        <Report />
      </Suspense>
    ),
  },

  // Static Pages
  {
    path: 'ec-member-role',
    element: (
      <Suspense>
        <ECMemberRole />
      </Suspense>
    ),
  },
  {
    path: 'category-of-elected-seats',
    element: (
      <Suspense>
        <CategoryOfElectedSeats />
      </Suspense>
    ),
  },
  {
    path: 'eligibility-criteria',
    element: (
      <Suspense>
        <EligibilityCriteria />
      </Suspense>
    ),
  },
  {
    path: 'candidate-profile-eligibility',
    element: (
      <Suspense>
        <CandidateProfileEligibility />
      </Suspense>
    ),
  },
  {
    path: 'voting-rules',
    element: (
      <Suspense>
        <VotingRules />
      </Suspense>
    ),
  },
  {
    path: 'code-of-conduct',
    element: (
      <Suspense>
        <CodeOfConduct />
      </Suspense>
    ),
  },
  {
    path: 'contact-us',
    element: (
      <Suspense>
        <ContactUs />
      </Suspense>
    ),
  },
];

export { ProtectedRoutes };
