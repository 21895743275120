import { createTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
export const breakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1300,
};
export const primaryColor = '#C93733';

const votingOverAllViewCart = keyframes`
  0% { background-color: #d9d9d9; }
  50% { background-color: #f9f9f9; }
  100% { background-color: #ffffff; }
`;

export const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#838383',
      light: '#83838370',
    },
    black: {
      main: '#000000',
    },
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
      [`@media (min-width:${breakpoints.sm}px)`]: {
        fontSize: '1.75rem',
      },
      [`@media (min-width:${breakpoints.md}px)`]: {
        fontSize: '1.75rem',
        fontWeight: 600,
        lineHeight: '2rem',
      },
      [`@media (min-width:${breakpoints?.lg}px)`]: {
        // lineHeight: '3.375rem',
        // fontSize: '1.75rem',
      },
      [`@media (min-width:${breakpoints.xl}px)`]: {
        fontSize: '2.25rem',
      },
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
      [`@media (min-width:${breakpoints.md}px)`]: {
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
      },
      [`@media (min-width:${breakpoints?.lg}px)`]: {
        // lineHeight: '3.375rem',
      },
      [`@media (min-width:${breakpoints.xl}px)`]: {
        fontSize: '1.75rem',
        fontWeight: 700,
        lineHeight: '2rem',
      },
    },
    h3: {
      color: '#636363',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      [`@media (min-width:${breakpoints.md}px)`]: {
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        fontWeight: 500,
      },
      [`@media (min-width:${breakpoints?.lg}px)`]: {
        // lineHeight: '2.5rem',
      },
      [`@media (min-width:${breakpoints.xl}px)`]: {
        fontWeight: 600,
        fontSize: '1.75rem',
        lineHeight: '2rem',
        // lineHeight: '3.375rem',
      },
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
      [`@media (min-width:${breakpoints.md}px)`]: {
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        fontWeight: 400,
      },
      [`@media (min-width:${breakpoints?.xl}px)`]: {
        fontSize: '2rem',
        fontWeight: 400,
        lineHeight: '2.25rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
      [`@media (min-width:${breakpoints?.lg}px)`]: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: '1.875rem',
        marginBottom: '0.625rem',
      },
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      [`@media (min-width:${breakpoints?.md}px)`]: {
        fontSize: '1.1rem',
        lineHeight: '1.15rem',
        fontWeight: 500,
      },
      [`@media (min-width:${breakpoints?.xl}px)`]: {
        fontSize: '1.2rem',
        lineHeight: '1.25rem',
        fontWeight: 500,
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          maxHeight: '54px',
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: '0px 4px 15px 0px #00000026',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontSize: '0.95rem',
          fontWeight: 400,
          lineHeight: '1.625rem',
          color: '#555555',
          marginBottom: '1rem',
          [`@media (min-width:${breakpoints?.md}px)`]: {
            fontSize: '1.1rem',
            lineHeight: '1.15rem',
            fontWeight: 500,
          },
          [`@media (min-width:${breakpoints?.xl}px)`]: {
            fontSize: '1.2rem',
            lineHeight: '1.25rem',
            fontWeight: 500,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline, &[readonly] .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-disabled': {
            border: 'none',
            backgroundColor: '#FAFAFA',
            color: '#838383',
          },
        },
      },
    },
  },
  customAnimations: {
    votingOverAllViewCart,
  },
});
