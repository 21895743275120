import { createContext, useReducer, useContext } from 'react';

const initialState = {
  election: null,
  nomination: null,
  fetchingElection: false,
  fetchingNomination: false,
};
export const ElectionContext = createContext();

export const electionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ELECTION_DETAILS':
      return { ...state, election: action.payload, fetchingElection: false };
    case 'SET_FETCHING_ELECTION_DETAILS':
      return { ...state, fetchingElection: action.payload };
    case 'SET_NOMINATION_DETAILS':
      return { ...state, nomination: action.payload, fetchingNomination: false };
    case 'SET_FETCHING_NOMINATION_DETAILS':
      return { ...state, fetchingNomination: action.payload };
    case 'CLEAR_CONTEXT':
      return { ...initialState };
    default:
      return state;
  }
};

export const ElectionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(electionReducer, initialState);
  return <ElectionContext.Provider value={{ ...state, dispatch }}>{children}</ElectionContext.Provider>;
};

export const useElectionContext = () => {
  const context = useContext(ElectionContext);

  if (!context) {
    throw new Error('useElectionContext must be used inside an ElectionContextProvider');
  }

  return context;
};
